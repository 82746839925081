import React from 'react';
import PropTypes from 'prop-types';

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

import Prose from '../../styles/typography/prose';
import Constrainer from '../../styles/constrainer';

import { MosaicFeatures, MosaicNumbers } from '../../components/mosaic';

import MediaMosaicBlock from '../../media/layout/company/company-wwd--cover.jpg';
import MediaDiptychBlock from '../../media/layout/company/company-diptych-b.jpg';
import Diptych from '../../components/diptych';
import LongForm from '../../components/long-form';
import SandboxLayout from './_layout';

const SandboxBlocks = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox'>
      <Constrainer>
        <Prose>
          <h2>Longform</h2>
          <p>Displaying long blocks of text, pull quote is optional.</p>
        </Prose>
      </Constrainer>

      <LongForm>
        <h2>Long form title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor.
        </p>
      </LongForm>
      <LongForm pullQuote='Consectetur adipiscing elit, sed do eiusmod tempor'>
        <h2>Long form title</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor.
        </p>
      </LongForm>

      <Constrainer>
        <Prose>
          <h2>Diptych: alpha & beta</h2>
          <p>Displaying media and text side by side.</p>
        </Prose>
      </Constrainer>

      <Diptych
        layout='alpha'
        title='Impactful data'
        src={MediaDiptychBlock}
        alt='Impactful data'
        // noDecoration
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor.
        </p>
      </Diptych>
      <Diptych
        layout='beta'
        title='Impactful data on beta'
        src={MediaDiptychBlock}
        alt='Impactful data'
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor.
        </p>
      </Diptych>

      <Constrainer>
        <Prose>
          <h2>Mosaic: Numbers</h2>
          <p>Displaying big numbers, such as stats, in a mosaic-fashion.</p>
        </Prose>
      </Constrainer>

      <MosaicNumbers
        items={[
          {
            value: 120,
            unit: 'km',
            title: 'So much mapping',
            body: 'The area professional mappers are able to cover by manually mapping the high-voltage electric grid'
          },
          {
            value: 3.5,
            unit: 'years',
            body: 'Time the average person spends mapping throughout a lifetime'
          },
          {
            value: 33,
            unit: 'x',
            body: 'Times that a key was pressed to do something special. Also no title'
          },
          {
            value: 15,
            title: 'No body nor unit'
          }
        ]}
      />

      <Constrainer>
        <Prose>
          <h2>Mosaic: Features</h2>
          <p>
            Displaying highlights, such as a list of company values, in a
            mosaic-fashion.
          </p>
        </Prose>
      </Constrainer>

      <MosaicFeatures
        items={[
          {
            media: { src: MediaMosaicBlock },
            alt: 'Mosaic media',
            title: 'Lorem ipsum dolor sit amet',
            body: 'We specialize in working with Earth data. To maximize and unlock the power of massive Earth and map data, we pay special attention to how data is distributed, analyzed, and used, and focus on the following to bridge the gap between potential and actual value of data.'
          },
          {
            title: 'Feature with title but no image',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'
          },
          {
            media: { src: MediaMosaicBlock },
            body: 'No title but an image. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.'
          },
          {
            media: { src: MediaMosaicBlock },
            alt: 'Mosaic media',
            title: 'Lorem ipsum dolor sit amet',
            body: 'We specialize in working with Earth data. To maximize and unlock the power of massive Earth and map data, we pay special attention to how data is distributed, analyzed, and used, and focus on the following to bridge the gap between potential and actual value of data.'
          }
        ]}
      />
    </SandboxLayout>
  );
};

export default SandboxBlocks;

SandboxBlocks.propTypes = {
  location: PropTypes.object
};
